body {
  background-color: black !important;
}

#root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@font-face {
  font-family: "Market_Deco";
  src: local("Market_Deco"), url("./fonts/Market_Deco.ttf") format("truetype");
  font-weight: bold;
}

.header {
  border: 5px solid white;
  display: flex;
  width: 17.5rem;
  flex-direction: row;
  margin: 1rem;
}

.header-logo {
  height: 5em;
}

.header-text {
  font-family: 'Market_Deco';
  margin: auto 0;
}

h1, h2, h3, h4, h5, h6, p, b {
  color: white;
}

.greeting {
  display: flex;
  flex-direction: row;
}

.dropdown-button {
  margin-left: 1rem;
}

.dropdown-search {
  margin: .25rem;
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
}

.action {
  margin-left: 1em;
  margin-right: 1em;
}

.message {
  margin: 2rem;
}

.statistics {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat {
  margin: 0;
  font-weight: 500;
}

.sign-button {
  margin: 2rem;
  height: 7rem;
  width: 15rem;
}

.footer {
  position: fixed;
  bottom: 1.5rem;
}

.nav-button {
  margin: 0 .3rem;
}

.dark-div {
  position: relative;
}

.dark-button {
  color: black;
  background-color: black;
  border-color: black;
  transition: 15s;
  position: absolute;
  right: 0;
}

.text-button:hover {
  color: #0D6EFD;
  transition: .2s;
  cursor: pointer;
}

.text-button {
  transition: .2s;
  user-select: none;
}

.actions {
  margin-top: 2rem;
}

.action {
  margin: .25rem;
}